import React from 'react';

import css from './MarketplaceSubHero.module.css';
import classnames from 'classnames';

const images = [
  {
    src: 'static/images/Business_Insider_Logo.svg',
    className: css.insider,
  },
  {
    src: 'static/images/MSN_Logo.svg',
    className: css.msn,
  },
  {
    src: 'static/images/National_Post_Logo.svg',
    className: css.national,
  },
  {
    src: 'static/images/Yahoo_Logo.svg',
    className: css.yahoo,
  },
  {
    src: 'static/images/Microsoft_Start_Logo.svg',
    className: css.microsoft,
  },
];

const MarketPlaceSubHero = props => {
  return (
    <div className={css.container}>
      <h4 className={css.heading}>As seen on</h4>

      <div className={css.logos}>
        <div className={css.logos_slide}>
          {images.map((image, index) => (
            <img key={index} src={image.src} alt="" />
          ))}
        </div>

        <div className={css.logos_slide}>
          {images.map((image, index) => (
            <img key={index} src={image.src} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

MarketPlaceSubHero.defaultProps = {};

MarketPlaceSubHero.propTypes = {};

export default MarketPlaceSubHero;
