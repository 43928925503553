import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import { CiCircleCheck } from 'react-icons/ci';
import css from './MarketPlaceCta.module.css';
import { useHistory } from 'react-router-dom';

const CtaContent = props => {
  return (
    <div className={css.ctaSubContent}>
      <h4 className={css.ctaSubHeading}>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.5752C6.82233 3.5752 2.625 7.77253 2.625 12.9502C2.625 18.1279 6.82233 22.3252 12 22.3252C17.1777 22.3252 21.375 18.1279 21.375 12.9502C21.375 7.77253 17.1777 3.5752 12 3.5752ZM0.375 12.9502C0.375 6.52988 5.57969 1.3252 12 1.3252C18.4203 1.3252 23.625 6.52988 23.625 12.9502C23.625 19.3705 18.4203 24.5752 12 24.5752C5.57969 24.5752 0.375 19.3705 0.375 12.9502Z"
            fill="#584CAF"
          />
          <path
            d="M17.2954 9.1547C17.7348 9.59403 17.7348 10.3064 17.2954 10.7457L11.2955 16.7456C10.8562 17.185 10.1438 17.185 9.7045 16.7456L6.7045 13.7457C6.26517 13.3064 6.26517 12.594 6.7045 12.1547C7.14384 11.7154 7.85616 11.7154 8.29549 12.1547L10.5 14.3592L15.7045 9.1547C16.1439 8.71536 16.8561 8.71536 17.2954 9.1547Z"
            fill="#584CAF"
          />
        </svg>{' '}
        {props.title}
      </h4>
      <p className={css.ctaInfo}>{props.description}</p>
    </div>
  );
};

CtaContent.defaultProps = {
  title: null,
  description: null,
};

CtaContent.propTypes = {
  title: string,
  description: string,
};

const MarketPlaceCta = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.centered}>
        <div className={css.sections}>
          <div className={css.ctaContent}>
            <h2 className={css.ctaHeading}>Game on, Your Way!</h2>

            <CtaContent
              title={'Instant Connections'}
              description={'Meet top brands and game studios without the hassle.'}
            />

            <CtaContent
              title={'Secure Payments'}
              description={'Get paid through our secure escrow system. No more payment worries.'}
            />

            <CtaContent
              title={'Boost your Reach'}
              description={'Get discovered by brands looking for talents like you.'}
            />

            <CtaContent
              title={'Easy Scheduling'}
              description={'Manage your gigs with our simple and intuitive calendar.'}
            />

            <div className={css.buttonContainer}>
              <button onClick={handleCtaClick} className={css.primaryButton}>
                Stream with Us
              </button>
            </div>
          </div>
          <div className={css.ctaVideoContainer}>
            <iframe
              className={css.ctaVideo}
              src="https://www.youtube.com/embed/hZQXp_AJenw?si=Qx2SGIkUwLqBFylz"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MarketPlaceCta.defaultProps = {};

MarketPlaceCta.propTypes = {};

export default MarketPlaceCta;
